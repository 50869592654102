<template>
  <div class="about">
    <el-card>
      <h1>实验到此结束，感谢您的参与！</h1>
      <h3>您的验证码为：<span style="background-color: #e5e5e5;padding: 10px 15px">{{uuid}}</span>请您将该验证码微信发送给我们的微信小助手，作为您参与实验的凭证。
        工作人员将在检查您的实验结果后与您确认奖励领取事宜。 </h3>
      <br>
      <h3> 在接下来的生活里，您可以继续使用我们的音乐聊天机器人聆听音乐，聆听自我，感受音乐与你的共鸣！让音乐给你带来更多的力量！</h3>
    </el-card>

  </div>
</template>
<script>

import instance from "@/request";

export default {
  name: "Thanks",
  data: function(){
    return{
      uuid: ""
    }
  },
  mounted() {
    instance.get('/user/get_uuid').then((res) => {
      this.uuid = res.data.msg
    }).catch(()=>{
      this.$message.error("网络错误，请刷新页面重试！")
    })
  }
}
</script>

<style scoped>
.about {
  padding: 40px;
}
</style>