<template>
  <div class="login wrapper">
<!--    <el-alert-->
<!--        title="我们的实验已于2022-08-22圆满结束，感谢您的关注和参与。⚠️请注意，您可以继续使用该系统，但2022-08-22之后完成该实验的参与者将无法获得奖励。"-->
<!--        type="error">-->
<!--    </el-alert>-->
    <h1>音乐聊天机器人研究-用户登录</h1>

    <div class="login-wrap ">
      <div class="agileits-top">
        <el-form ref="loginForm" :model="user" :rules="rules" status-icon label-position="top"
                 @keyup.enter.native="doLogin">
          <el-form-item prop="username" label="用户名">
            <el-input v-model="user.username" placeholder="请输入您的用户名" autofocus></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input v-model="user.password" placeholder="请输入您的密码" show-password autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button round type="primary" icon="el-icon-key" @click="doLogin()" :disabled=!canSubmit>登 录</el-button>
            &nbsp;
            <el-link type="primary" icon="el-icon-user" :underline="false" href="/#/register">去注册</el-link>
          </el-form-item>
          <span
              style="color: #595a5a;font-size: 12px">如果您忘记用户名、密码或者在登录过程中遇到问题，请通过微信联系工作人员。</span>
        </el-form>
      </div>
    </div>
    <ul class="w3lsg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>

</template>

<script>
import instance from "@/request";

export default {
  name: "login",
  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      rules: {
        username: [{required: true, message: "用户名必填！", trigger: 'blur'}],
        password: [{required: true, message: "密码必填!", trigger: 'blur'}]
      }
    }
        ;
  },
  created() {
  },
  methods: {
    doLogin: function () {
      if (!this.user.username) {
        this.$message.error("请输入用户名！");
        return false;
      } else {
        let params = {
          username: this.user.username,
          password: this.user.password
        };
        //校验用户名和密码是否正确;
        instance.post("/user/token", this.$qs.stringify(params)).then(res => {
          // console.log("输出response.data.status", res.data.status);
          if (res.status === 200) {
            if (res.data.code === 1) {
              //可以登录
              if (res.data.disabled === 0) {
                sessionStorage.setItem('token', res.data['access_token'])
                sessionStorage.setItem('user_control', res.data.user_control)
                sessionStorage.setItem('social_info', res.data.social_info)
                sessionStorage.setItem('pre_study', res.data.pre_study)
                sessionStorage.setItem('post_study', res.data.post_study)
                sessionStorage.setItem('listen_count', res.data.listen_count)
                sessionStorage.setItem('tutorial', res.data.tutorial)
                if (res.data.pre_study === 0) {
                  this.$router.replace({path: "/pre1"});
                } else if (res.data.tutorial === 0) {
                  this.$router.replace({path: "/tutorial"});
                } else {
                  this.$router.replace({path: "/music"});
                }
              } else {
                this.$message.error("您的账户暂未启用或者已被禁用，请联系工作人员！");
              }
            } else {
              this.$message.error("每天23：00--24：00是系统维护时间，请明天再来！");
            }
          } else {
            this.$message.error("网络错误，请检查用户名和密码后重试！")
          }
        });
      }
    }
  },
  computed: {
    canSubmit() {
      const {username, password} = this.user
      return Boolean(username && password)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

h1 {
  font-size: 3em;
  text-align: center;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 6px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-- start editing from here --*/
a {
  text-decoration: none;
}

.txt-rt {
  text-align: right;
}

/* text align right */
.txt-lt {
  text-align: left;
}

/* text align left */
.txt-center {
  text-align: center;
}

/* text align center */
.float-rt {
  float: right;
}

/* float right */
.float-lt {
  float: left;
}

/* float left */
.clear {
  clear: both;
}

/* clear float */
.pos-relative {
  position: relative;
}

/* Position Relative */
.pos-absolute {
  position: absolute;
}

/* Position Absolute */
.vertical-base {
  vertical-align: baseline;
}

/* vertical align baseline */
.vertical-top {
  vertical-align: top;
}

/* vertical align top */
nav.vertical ul li {
  display: block;
}

/* vertical menu */
nav.horizontal ul li {
  display: inline-block;
}

/* horizontal menu */
img {
  max-width: 100%;
}

/*-- end reset --*/

.wrapper {
  height: 100%;
}

/*-- main --*/
.login {
  padding: 3em 0 0;
}

.login-wrap {
  width: 35%;
  margin: 3.5em auto;
  background: rgba(255, 255, 255, 0.75);
  /*background: #e5e5e5;*/
  background-size: cover;
  border-radius: 10px;
}

.agileits-top {
  padding: 3em 3em 3em;
}

input[type="text"], input[type="password"] {
  font-size: 1em;
  color: #fff;
  font-weight: 100;
  margin: 3em 0 0;
  width: 93%;
  display: block;
  border: none;
  padding: 1em;
  border-bottom: solid 1px #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 96%, #fff 4%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #fff 4%);
  background-position: -800px 0;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
}

.text:focus, .text:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
}

.text:focus::-webkit-input-placeholder, .text:valid::-webkit-input-placeholder {
  color: #FFC107;
  font-size: .9em;
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  visibility: visible !important;
}

::-webkit-input-placeholder {
  color: #fff;
  font-weight: 100;
}

:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

input[type="submit"] {
  font-size: 1em;
  color: #fff;
  background: #6439af;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1em;
  -webkit-appearance: none;
  width: 100%;
  margin: 3em 0;
}

input[type="submit"]:hover {
  background: #8a2be2;
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  -o-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -o-transition: .5s all;
  -ms-transition: .5s all;
  transition: .5s all;
}

.agileits-top p {
  font-size: 1em;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
}

.agileits-top p a {
  color: #fff;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  transition: .5s all;
}

.agileits-top p a:hover {
  color: #FFC107;
}

@keyframes rippling {
  50% {
    border-left-color: #FFC107;
  }
  100% {
    border-bottom-color: #FFC107;
    border-left-color: #FFC107;
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
}

.w3lsg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.w3lsg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  -moz-animation: square 25s infinite;
  -o-animation: square 25s infinite;
  -ms-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  transition-timing-function: linear;
}

.w3lsg-bubbles li:nth-child(1) {
  left: 10%;
}

.w3lsg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  -moz-animation-duration: 17s;
  -o-animation-duration: 17s;
  animation-duration: 17s;
}

.w3lsg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
  animation-delay: 4s;
}

.w3lsg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  -moz-animation-duration: 22s;
  -o-animation-duration: 22s;
  -ms-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}

.w3lsg-bubbles li:nth-child(5) {
  left: 70%;
}

.w3lsg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}

.w3lsg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  -moz-animation-delay: 7s;
  -o-animation-delay: 7s;
  -ms-animation-delay: 7s;
  animation-delay: 7s;
}

.w3lsg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  -moz-animation-duration: 40s;
  animation-duration: 40s;
}

.w3lsg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}

.w3lsg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

/*-- responsive-design --*/
@media (max-width: 1280px) {
  h1 {
    font-size: 2.6em;
  }

  .login-wrap {
    width: 40%;
  }
}

@media (max-width: 1080px) {
  .login-wrap {
    width: 45%;
  }
}

@media (max-width: 991px) {
  h1 {
    font-size: 2.4em;
  }
}

@media (max-width: 900px) {
  input[type="text"], input[type="password"] {
    margin: 2.5em 0 0;
    width: 90%;
  }

  .login-wrap {
    width: 52%;
    margin: 3em auto;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 2.2em;
  }
}

@media (max-width: 736px) {
  .agileits-top {
    padding: 0.5em 2.5em 2.5em;
  }

  .login-wrap {
    width: 62%;
    margin: 3em auto;
  }
}

@media (max-width: 667px) {
  .login-wrap {
    width: 67%;
  }

  input[type="submit"] {
    padding: 0.9em;
  }
}

@media (max-width: 600px) {
  input[type="text"], input[type="password"] {
    padding: 1em 0.9em;
  }

  input[type="submit"] {
    margin: 2.5em 0;
  }

  h1 {
    font-size: 2em;
    letter-spacing: 5px;
  }
}

@media (max-width: 568px) {
  .login-wrap {
    width: 75%;
    margin: 2em auto;
  }

}

@media (max-width: 414px) {
  h1 {
    font-size: 1.8em;
    letter-spacing: 2px;
  }

  .login-wrap {
    width: 85%;
    margin: 1.5em auto;
  }

  .agileits-top {
    padding: 0.5em 2em 2em;
  }

  input[type="text"], input[type="password"] {
    font-size: .9em;
    width: 95%;
    padding: .8em .5em;
  }

  .text:focus, .text:valid {
    background-position: 0 0px;
  }

  input[type="submit"] {
    font-size: 0.9em;
  }

  .agileits-top p {
    font-size: 0.9em;
  }

}

@media (max-width: 384px) {
  .login-wrap {
    width: 88%;
  }

  input[type="text"], input[type="password"] {
    width: 94%;
  }

}

@media (max-width: 375px) {
  .agileits-top p {
    letter-spacing: 0px;
  }
}

@media (max-width: 320px) {
  .login {
    padding: 1.5em 0 0;
  }

  h1 {
    font-size: 1.5em;
    letter-spacing: 1px;
  }

  .agileits-top {
    padding: 0.5em 1.5em 1.8em;
  }

  .main-agileinfo {
    width: 92%;
  }

  .text:focus, .text:valid {
    background-position: 0 0px;
  }

  input[type="text"], input[type="password"] {
    font-size: .85em;
    margin: 1.8em 0 0;
  }

  input[type="submit"] {
    margin: 1.5em 0;
    padding: 0.8em;
  }
}

</style>
