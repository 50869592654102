<template>
  <div class="prestudy">
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <el-card class="box-card" shadow="hover">
          <h1>用户实验-前测问卷</h1>
          <el-form ref="preStudyform_1" :model="prestu_1" label-position="top">
            <h3>
              下列题目目的在于了解您平时进行音乐活动（如聆听音乐）的习惯。请仔细阅读下列句子的描述，选择最符合您个人真实情况的选项。</h3>
            <h4>1.非常不同意，2.不同意，3.有点不同意，4.不同意也不反对，5.有点同意，6.同意，7.非常同意</h4>
            <el-form-item :prop="item.t" v-for="(item, index) in q1group" :label="index+1+'.'+ item.q" :key="item.t"
                          :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="prestu_1[item.t]">
                <el-radio-button label="1">非常不同意</el-radio-button>
                <el-radio-button label="2">不同意</el-radio-button>
                <el-radio-button label="3">有点不同意</el-radio-button>
                <el-radio-button label="4">不同意也不反对</el-radio-button>
                <el-radio-button label="5">有点同意</el-radio-button>
                <el-radio-button label="6">同意</el-radio-button>
                <el-radio-button label="7">非常同意</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :prop="q2.t" :label="'15.'+q2.q" :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="prestu_1[q2.t]">
                <el-radio-button label="1">0场</el-radio-button>
                <el-radio-button label="2">1场</el-radio-button>
                <el-radio-button label="3">2场</el-radio-button>
                <el-radio-button label="4">3场</el-radio-button>
                <el-radio-button label="5">4-6场</el-radio-button>
                <el-radio-button label="6">7-10场</el-radio-button>
                <el-radio-button label="7">11场以上</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :prop="q3.t" :label="'16.'+ q3.q" :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="prestu_1[q3.t]">
                <el-radio-button label="1">0到15分钟</el-radio-button>
                <el-radio-button label="2">15到30分钟</el-radio-button>
                <el-radio-button label="3">30到60分钟</el-radio-button>
                <el-radio-button label="4">60到90分钟</el-radio-button>
                <el-radio-button label="5">2小时</el-radio-button>
                <el-radio-button label="6">2到3小时</el-radio-button>
                <el-radio-button label="7">4小时以上</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="text-align: left">
              <el-button round type="primary" icon="el-icon-s-promotion" @click="onSubmit('preStudyform_1')">下一步
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import instance from "@/request";

export default {
  name: "PreStudyQue1",
  data() {
    return {
      //问卷内容放在这里
      prestu_1: {},
      //问题部分
      q1group: [
        {q: "我闲暇时花很多时间进行音乐相关活动。", t: "active_engagement_1"},
        {q: "我有时会选择听让我起鸡皮疙瘩的音乐。", t: "emotion_1"},
        {q: "我乐于书写与音乐有关的文字，例如在博客或论坛上。", t: "active_engagement_2"},
        {q: "我对自己不熟悉的音乐风格充满好奇心，也会想要再更深入了解它。", t: "active_engagement_3"},
        {q: "乐曲很少引起我的情绪。", t: "emotion_2"},
        {q: "我经常阅读或在网络上搜寻与音乐有关的事物。", t: "active_engagement_4"},
        {q: "我经常挑选某种音乐来激励自己或提振精神。", t: "emotion_3"},
        {q: "听到音乐时，我能指出它的特色在哪里。", t: "emotion_4"},
        {q: "这个题目请选择: 有点同意。", t: "attention_check_1"},
        {q: "我能够将一段音乐激发起的情绪表达出来。", t: "emotion_5"},
        {q: "我不会把很多可支配的钱花在音乐上。", t: "active_engagement_5"},
        {q: "我算是对音乐上瘾 ── 没有音乐我活不下去。", t: "active_engagement_6"},
        {q: "我会追踪听到的新音乐（例如新的歌手或新录音）。", t: "active_engagement_7"},
        {q: "音乐能让我回想起过去的人物或地方。", t: "emotion_6"},
      ],
      q2: {q: "过去12个月以来，我以观众身份参加的线下/线上直播音乐活动共有__场。", t: "active_engagement_8"},
      q3: {q: "我每天专心聆听音乐的时间有__。", t: "active_engagement_9"},
    };
  },
  mounted() {
    //获得当前页载入时间作为问卷开始时间
    this.prestu_1.preQue_start_time = new Date().getTime();
    if (sessionStorage.getItem("pre_study") === '1') {
      this.$loading({
        lock: true,
        text: '您已经提交过该问卷，请不要重复提交！',
        spinner: 'el-icon-error',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.prestu_1);
          instance.post("/que/pre_que1", this.prestu_1).then(res => {
            if (res.status === 200) {
              //在该页面防止重复提交
              this.$message({
                message: res.data.msg,
                type: 'success',
                center: true,
                duration: 1500,
                onClose: () => {
                  //此处写提示关闭后需要执行的函数
                  this.$router.push({path: "/pre2"});
                }
              })
            } else {
              this.$message.error("错误，请重试！")
            }
          }).catch(() => {
            this.$message.error("错误，请重试！")
          });
        } else {
          this.$message.error("请将问卷填写完整！")
          return false;
        }
      });
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prestudy {
  padding: 64px 0;
}
</style>
<style>
.el-form-item__label{
  font-weight: bolder !important;
  font-size: 16px !important;
  color: #2b2c41;
}
</style>