import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import qs from 'qs'
import Vuex from 'vuex'
import AudioPlayer from '@liripeng/vue-audio-player'
import ba from 'vue-ba'
import VueCoreVideoPlayer from 'vue-core-video-player'
import './assets/icon/iconfont.css'

Vue.use(VueCoreVideoPlayer)
Vue.config.productionTip = false
Vue.prototype.$qs = qs
Vue.use(ba, '0975e9e42a69d6418b42d047e1fba333')
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.use(AudioPlayer)

new Vue({
    router, render: h => h(App)
}).$mount('#app')
