<template>
  <div class="tutorial">
    <el-row type="flex" justify="center">
      <el-col span="22">
        <el-card class="box-card">
          <h2 style="text-align: center;"> 音乐聊天机器人介绍 </h2>
          <el-image
            src="https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_1.png">
          </el-image>
          <br/>
          <el-image 
            src="https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_2.png">
          </el-image>
          <br/>
          <el-image
            :src="url">
          </el-image>
          <br/>
          <h3> 完成5天“聆听音乐·聆听自我“任务后，填写评估音乐聊天机器人的问卷即完成实验。</h3>
          <h3>注意: 23:00 - 24:00 是系统维护时间。请在00:00-23:00使用音乐聊天机器人，谢谢大家的配合！</h3>
          <br/>

          <div style="text-align: center">
            <el-button @click="tutorialVisible=true" style="text-align: center" type="primary">
              我已认真阅读音乐聊天机器人的介绍，现在开始实验
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog title="测验" :visible.sync="tutorialVisible">
      <el-form :model="user_ans" label-position="top" ref="quizForm">
        <el-form-item prop="q1" label="1. 本次实验我需要完成多少天聆听音乐·聆听自我的任务？" required>
          <el-radio-group v-model="user_ans.q1" label-position="top">
            <el-radio-button label="1">4天</el-radio-button>
            <el-radio-button label="2">5天</el-radio-button>
            <el-radio-button label="3">6天</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="q2" label="2. 本次实验除了聆听音乐我还需要做什么？" required>
          <el-radio-group v-model="user_ans.q2">
            <el-radio-button label="1">唱歌</el-radio-button>
            <el-radio-button label="2">体会和抒发自己的内心情感</el-radio-button>
            <el-radio-button label="3">评价音乐的情感</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="q3" label="3. 每天需要完整聆听多少首歌曲？" required>
          <el-radio-group v-model="user_ans.q3">
            <el-radio-button label="1">5首</el-radio-button>
            <el-radio-button label="2">6首</el-radio-button>
            <el-radio-button label="3">7首</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="q4" label="4.该实验要评估的系统是一个：____" required>
          <el-radio-group v-model="user_ans.q4">
            <el-radio-button label="1">客服机器人</el-radio-button>
            <el-radio-button label="2">音乐聊天机器人</el-radio-button>
            <el-radio-button label="3">闲聊机器人</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="check_ans('quizForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import instance from "@/request";

export default {
  name: "Introduction",
  data: function () {
    return {
      url:"",
      tutorialVisible: false,
      answer: {q1: '2', q2: '2', q3: '1', q4: '2'},
      user_ans: {q1: null, q2: null, q3: null, q4: null}
    }
  },
  methods: {
    check: function () {

      for (let i in this.answer) {
        if (this.answer[i] !== this.user_ans[i]) {
          return false
        }
      }
      return true
    },
    check_ans: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //判断是不是正确的
          if (this.check()) {
            // 回答正确
            instance.post('/que/tutorial').then((res)=>{
              if(res.data.code === 1){
                this.$message({
                  message: "回答正确，请继续！",
                  type: 'success',
                  center: true,
                  duration: 1500,
                  onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    this.$router.push({path: "/music"});
                  }
                })
              }else {
                this.$message.error("服务器验证错误！")
              }
            }).catch(()=>{
              this.$message.error("网络错误，请稍后重试！")
            })

          } else {
            this.$message({
              message: "回答错误，请重新阅读",
              type: 'error',
              center: true,
              duration: 1500,
              onClose: () => {
                //此处写提示关闭后需要执行的函数
                this.tutorialVisible = false;
                this.user_ans = {}
                this.$refs[formName].resetFields();
              }
            })
          }
        } else {
          this.$message.error("请填写完整！");
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created() {
    if(sessionStorage.getItem('social_info')==='1'){
      if(sessionStorage.getItem('user_control')==='1') {
        this.url = "https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_3_S_S.png"
      }else {
        this.url = "https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_3_U_S.png"
      }
    }else {
      if(sessionStorage.getItem('user_control')==='1') {
        this.url = "https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_3_S_NS.png"
      }else {
        this.url = "https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/tutorial_3_U_NS.png"
      }
    }
  }
}
</script>

<style scoped>
.tutorial {
  box-sizing: border-box;
  margin-top: 64px;
}
</style>