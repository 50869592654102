<template>
  <div class="intro">
    <el-row type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <h3>感谢您注册参与本次实验！</h3>
          <div class="player-container">
            <vue-core-video-player ref="video_player" @canplay="play_video" :autoplay="true" controls="auto"
                                   src="https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/introduction.mp4"></vue-core-video-player>
          </div>
          <p style="margin-top: 20px">
            为了更好地进行这项为期一周的实验研究，我们想<b>请您扫描下面的二维码或搜索微信号(hkbu_hci)
            添加我们的微信小助手。</b>
          </p>
          <p>验证信息: 请填写您注册时的邮箱账号以供我们工作人员验证。谢谢！</p>
          <el-image
              style="width: 400px; height: 500px"
              src="https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/Wechat-QRCode.jpeg">
          </el-image>
          <h4>添加微信的目的: </h4>
          <ol>
            <li>在未来的一周内定时提醒参与者完成当日''聆听音乐·聆听自我''的任务。</li>
            <li>实验结束后方便联系参与者发放价值100元的京东电子礼品卡。</li>
          </ol>
          <h4 style="color: #f56c6c">科研诚不易，您的时间也宝贵。对待实验认真投入对我们的研究非常重要！
            您的付出将会助力科技让生活更美好的愿景！</h4>
          <p>
            注: 由于23:00 - 24:00 是系统维护时间，烦请大家在00:00-23:00期间使用系统进行登录操作，感谢！
          </p>
          <br/>
          <div style="text-align: center">
            <el-button @click="back_to_login" style="text-align: center" type="primary">我已添加微信，去登录</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "Introduction",
  methods: {
    play_video: function () {
      this.$refs.video_player.play()
    },
    back_to_login: function () {
      this.$router.replace("/")
    }
  },
  mounted() {
    this.play_video()
  }
}
</script>

<style scoped>
.intro {
  box-sizing: border-box;
  margin-top: 64px;
}

b {
  color: #f56c6c;
}
</style>