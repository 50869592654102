<template>
  <div class="prestudy">
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <el-card class="box-card" shadow="hover">
          <h1>用户实验-后测问卷</h1>
          <el-form ref="postStudyform_1" :model="poststu_1" label-position="top">
            <h3>
              以下部分旨在了解你使用这个音乐聊天机器人的体验。当中某些陈述句子可能比较相似，而有些则更能反映你的看法。请仔细留心句子的细节，选择最符合您个人真实体验的选项。</h3>
            <h4>1. 非常不同意，2. 不同意，3. 有点不同意，4. 不同意也不反对，5. 有点同意，6. 同意，7. 非常同意。</h4>
            <el-form-item :prop="item.t" v-for="(item, index) in q1group" :label="index+1+'.'+ item.q" :key="item.t"
                          :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="poststu_1[item.t]">
                <el-radio-button label="1">非常不同意</el-radio-button>
                <el-radio-button label="2">不同意</el-radio-button>
                <el-radio-button label="3">有点不同意</el-radio-button>
                <el-radio-button label="4">不同意也不反对</el-radio-button>
                <el-radio-button label="5">有点同意</el-radio-button>
                <el-radio-button label="6">同意</el-radio-button>
                <el-radio-button label="7">非常同意</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item style="text-align: left">
              <el-button round type="primary" icon="el-icon-s-promotion" @click="onSubmit('postStudyform_1')">下一步
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import instance from "@/request";

export default {
  name: "PostStudyQue1",
  data() {
    return {
      //问卷内容放在这里
      poststu_1: {},
      //问题部分
      q1group: [
        {q: "我对自己在使用这个音乐聊天机器人时的表现感到满意。", t: "competence_1"},
        {q: "在使用这个音乐聊天机器人时，我有不同的操作选择。", t: "autonomy_1"},
        {q: "这个音乐聊天机器人让我感觉到自己是某一群体中的一份子。", t: "relatedness_1"},
        {q: "我觉得自己擅长使用这个音乐聊天机器人。", t: "competence_2"},
        {q: "这个音乐聊天机器人不能支持我与他人建立有意义的联系。", t: "relatedness_2"},
        {q: "我觉得可以通过这个音乐聊天机器人自由地表达我的想法和意见。", t: "autonomy_2"},
        {q: "我觉得自己是有能力使用这个音乐聊天机器人的用户。", t: "competence_3"},
        {q: "当我使用这个音乐聊天工具时，我觉得自己没有受到外界的压力。", t: "autonomy_3"},
        {q: "这个音乐聊天机器人并不支持我与他人有意义的联系。", t: "relatedness_3"},


        {q: "在与这个音乐聊天机器人交互时，我不知所措。", t: "ue_FA_1"},
        {q: "在使用这个音乐聊天机器人时，我感到失望。", t: "ue_PU_1"},
        {q: "这个音乐聊天机器人吸引了我。", t: "ue_AE_3"},
        {q: "我认为与这个音乐聊天机器人的交互体验是有趣的。", t: "ue_RW_3"},
        {q: "我打算在我的生活中使用这个音乐聊天机器人。", t: "TAM_BI_1"},
        {q: "我与这个音乐聊天机器人的互动是清楚的和可理解的。", t: "TAM_PEOU_1"},
        {q: "我发现这个音乐聊天机器人使用起来令人困惑。", t: "ue_PU_2"},
        {q: "我与这个音乐聊天机器人交互的时间转瞬即逝。", t: "ue_FA_2"},
        {q: "使用这个音乐聊天机器人会提高我对生活的满意度。", t: "TAM_PU_2"},
        {q: "这个题目请选择: 同意。", t: "attention_check_3"},
        {q: "我觉得很容易使用这个音乐聊天机器人做我想做的事。", t: "TAM_PEOU_2"},
        {q: "使用这个音乐聊天机器人让我感觉费劲。", t: "ue_PU_3"},
        {q: "这个音乐聊天机器人有吸引力。", t: "ue_AE_1"},
        {q: "我期望我在未来会使用这个音乐聊天机器人。", t: "TAM_BI_2"},
        {q: "我使用这个音乐聊天机器人的经历是有意义的。", t: "ue_RW_2"},
        {q: "这个音乐聊天机器人有美感。", t: "ue_AE_2"},
        {q: "使用这个音乐聊天机器人会提高我对自己内心感受的觉察。", t: "TAM_PU_3"},
        {q: "当使用这个音乐聊天机器人时, 我会沉浸在其中。", t: "ue_FA_3"},
        {q: "使用这个音乐聊天机器人是值得的。", t: "ue_RW_1"},
        {q: "我认为这个音乐聊天机器人在我的生活中是有用的。", t: "TAM_PU_1"},
        {q: "我认为这个音乐聊天机器人容易使用。", t: "TAM_PEOU_3"},
        {q: "我打算在未来经常使用这个音乐聊天机器人。", t: "TAM_BI_3"}
      ],
    };
  },
  mounted() {
    //获得当前页载入时间作为问卷开始时间
    this.poststu_1.start_time = new Date().getTime();
    if (sessionStorage.getItem("post_study") === '1') {
      this.$loading({
        lock: true,
        text: '您以及提交过该问卷，请不要重复提交！',
        spinner: 'el-icon-error',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.poststu_1);
          instance.post("/que/post_que1", this.poststu_1).then(res => {
            if (res.status === 200) {
              //在该页面防止重复提交
              this.$message({
                message: res.data.msg,
                type: 'success',
                center: true,
                duration: 1500,
                onClose: () => {
                  //此处写提示关闭后需要执行的函数
                  this.$router.push({path: "/post2"});
                }
              })
            } else {
              this.$message.error("错误，请重试！")
            }
          }).catch(() => {
            this.$message.error("错误，请重试！")
          });
        } else {
          this.$message.error("请将问卷填写完整！")
          return false;
        }
      });
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prestudy {
  padding: 64px 0;
}
</style>
