<template>
  <div class="login wrapper">
<!--    <el-alert-->
<!--        title="现阶段我们的实验名额已满。"-->
<!--        type="error">-->
<!--    </el-alert>-->
    <h1>音乐聊天机器人研究 - 用户注册 </h1>
    <div class="login-wrap ">
      <div class="agileits-top">
        <el-form ref="registerForm" :model="user" :rules="rules" status-icon label-position="top">
          <el-form-item prop="username">
            <label for="" style="color: white">用户名：</label>
            <el-input v-model="user.username" placeholder="请输入您的用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password" show-password>
            <label for="password" style="color: white">密码：</label>
            <el-input v-model="user.password" placeholder="请输入您的密码" show-password></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <label for="email" style="color: white">邮箱：</label>
            <el-input v-model="user.email" placeholder="请输入您的邮箱"></el-input>
          </el-form-item>
          <hr>
          <el-form-item prop="age">
            <label for="age" style="color: white">年龄：</label>
            <el-input v-model="user.age" placeholder="请输入您的年龄"
                      oninput="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item prop="gender">
            <label for="gender" style="color: white">性别：</label>
            <el-radio-group v-model="user.gender">
              <el-radio-button label="女"></el-radio-button>
              <el-radio-button label="男"></el-radio-button>
              <el-radio-button label="其它"></el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="occupation">
            <label for="occupation" style="color: white">职业：</label>
            <el-radio-group v-model="user.occupation">
              <el-radio-button label="学生"></el-radio-button>
              <el-radio-button label="工作"></el-radio-button>
              <el-radio-button label="自由职业"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button round type="primary" icon="el-icon-user" @click="doRegister('registerForm')">注册账户</el-button>&nbsp;
            <el-link type="primary" icon="el-icon-key" :underline="false" href="/#/login">去登录</el-link>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <ul class="w3lsg-bubbles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>

    <el-dialog
        :visible="consent"
        width="60%"
        title="香港浸会大学知情同意书"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center>
      <h3>研究目的：</h3>
      <p>
        本研究的目的是设计一个音乐聊天机器人，旨在帮助人们在聆听音乐的过程中体会并抒发自己的情感与感受，从而放松身心。
      </p>
      <h3>实验过程：</h3>
      <p>
        本项研究的实验任务主要包含五个步骤：</p>
      <ol>
        <li>1. 注册参与实验的账号。</li>
        <li>2. 回答一个关于您个人背景信息的问卷。</li>
        <li>3. 阅读音乐聊天机器人的图文介绍并回答实验相关问题。</li>
        <li>4.
          在未来的一周内使用我们音乐聊天机器人5天，每天完整聆听5首歌，并在聆听过程中体会和抒发自己听歌时的情感或感受。
        </li>
        <li>5. 填写一份关于该音乐聊天机器人使用体验的问卷。</li>
      </ol>
      <h3>实验时长：</h3>
      <p>这项实验预计每天将花费您大约15-20分钟时间。</p>
      <h3>研究效益：</h3>
      <p>
        通过参加这项研究，您可以聆听音乐并体会自己的感受从而放松身心。此外，我们相信您的反馈将为我们设计一个改善情绪的音乐应用提供重要帮助。</p>
      <h3>风险及隐私保障：</h3>
      <p>
        参加这项研究没有任何风险。您提供的数据是匿名的，所有收集到的数据将被加密存放在香港浸会大学的服务器上，且仅用于研究目的。</p>
      <h3>参与者权利：</h3>
      <p>
        参与这项研究是自愿的，您可以拒绝参与且不受任何处罚。如果您决定参与，您可以在任何时候退出研究而不受任何处罚。如果您在数据收集完成前退出研究，您的数据将被销毁。</p>
      <h3>可能导致我们终止您参与的情况：</h3>
      <p>如果我们发现在您参与过程中存在不遵守实验指示操作的行为，我们可能会终止您的参与。</p>
      <h3>参与者奖励：</h3>
      <p>为了感谢您对我们研究的支持，成功完成实验将会获得价值100元的京东电子礼品卡。</p>
      <h3>联系方式：</h3>
      <p>如果您对这项研究有任何疑问，请随时通过以下方式与我们联系：<br/>
        香港浸会大学计算机科学系，蔡婉铃(cswlcai@comp.hkbu.edu.hk)。</p>
      <p>
        如果您觉得没有受到如本知情同意书中所述的应有对待，或者您作为本研究的参与者的权利在实验过程中受到了侵犯，您可以通过电子邮件联系香港浸会大学研究伦理委员会（hkbu_rec@hkbu.edu.hk）。</p>
      <br/>
      <p>
        <b>点击 "接受 "按钮表明您已年满18周岁，自愿同意参与这项研究，且充分理解本知情同意书中的内容。</b>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="accept" :disabled="btn_censent">接受<span v-show="timer">({{ count }}s)</span></el-button>
      </span>
    </el-dialog>
    <el-dialog
        :visible="tips"
        width="60%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center>
      <h2>尊敬的参与者:</h2>
      <h3>
        非常感谢您对我们音乐聊天机器人实验的关注！
        由于大家的热情参与，现阶段我们的<span style="color: #f56c6c">实验名额已满</span>。为保证您的权益和参与者的用户体验，我们决定暂时关闭系统注册。由此给您带来的不便敬请谅解。

        再次感谢您对我们实验的大力支持！</h3>
    </el-dialog>
    <el-dialog
        :visible="intro"
        width="60%"
        title="音乐聊天机器人实验介绍"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center>
      <p>
        现代社会，生活节奏快、学习工作压力大。
      </p>
      <p>
        忙碌中的你，是否时常感受到压力？或是焦虑？
      </p>
      <p>
        我们是香港浸会大学计算机科学系的研究人员，目前设计了一个音乐聊天机器人，
        <b style="color: #f56c6c;">旨在帮助人们在聆听音乐的过程中体会并抒发自己的内心情感和感受，从而放松身心。</b>
      </p>
      <p>我们诚邀您参与我们为期一周（5天任务）的研究，预计每天需要花费您大约15-20分钟的时间。</p>
      <p>整个实验包含五个步骤：</p>
      <el-image
          src="https://musicbot-1251052535.cos.ap-shanghai.myqcloud.com/imgs/fig_study_procedure.png">
      </el-image>

      <p>为了感谢您对我们研究的支持，成功完成实验将会获得价值100元的京东电子礼品卡。</p>
      <h3>提示信息:</h3>
      <ol>
        <li>I.通过您的<b style="color: #f56c6c;">电脑</b>即可参与这项研究。</li>
        <li>II.我们建议您使用Chrome或Safari浏览器以获得更好的体验。</li>
        <li>
          III.香港浸会大学研究伦理委员会已于2022年8月批准了本研究的伦理（人类/非临床）审核。您的所有数据都将被保密，并只用于我们的研究分析。
        </li>
      </ol>
      <br/>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="intro=false" :disabled="btn_intro">我已阅读并了解<span
            v-show="timer">({{ count }}s)</span></el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import instance from '@/request';

const TIME_COUNT = 15 // 倒计时的时间
const dateOptions = ['2022-07-31 20:00', '2022-08-01 20:00', '2022-08-02 20:00'];
export default {
  name: "register",
  data() {
    let checkPwd = (rule, value, callback) => {
      const regPwd = /^[A-Za-z0-9]+$/;
      if (regPwd.test(value)) {
        // 合法名称
        return callback()
      }
      callback(new Error('仅可以使用大小写字母和数字'))
    }
    let checkEmail = (rule, value, callback) => {
      const regEmail = /^[0-9A-Za-zd]+([-_.][0-9A-Za-zd]+)*@([0-9A-Za-zd]+[-.])+[0-9A-Za-zd]{2,5}$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('请输入合法的邮箱地址'))
    }
    let checkAge = (rule, value, callback) => {
      const regAge = /^[0-9]*$/
      if (regAge.test(value)) {
        if (value < 18) {
          callback(new Error('必须年满18岁'));
        } else {
          return callback()
        }
      } else {
        callback(new Error('请输入数字'))
      }
    }
    return {
      btn_censent: true,
      btn_intro: true,
      timer: null,
      count: '',
      //阅读计时器
      tips: false,
      consent: false,
      intro: false,
      tutorial_date: dateOptions,
      user: {
        username: "",
        password: "",
        email: "",
        occupation: "",
        age: "",
        gender: "",
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 4, max: 36, message: '长度在4到36个字符', trigger: 'blur'},
          {validator: checkPwd, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 36, message: '密码长度在6到36个字符', trigger: 'blur'},
          {validator: checkPwd, trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入电子邮件地址', trigger: 'blur'},
          {validator: checkEmail, trigger: 'blur'}
        ],
        age: [
          {required: true, message: '请输入您的年龄', trigger: 'blur'},
          {validator: checkAge, trigger: 'blur'}
        ],
        gender: [
          {required: true, message: '请选择您的性别', trigger: 'change'},
        ],
        occupation: [
          {required: true, message: '请选择您的职业', trigger: 'change'},
        ],
        meeting: [
          {type: 'array', required: true, message: '请至少选择一个时间', trigger: 'change'}
        ],
      },

    };
  },
  created() {
  },
  methods: {
    accept: function () {
      this.consent = false;
      this.intro = true;
      this.count = TIME_COUNT
      this.btn_intro = true
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
        } else {
          this.btn_intro = false
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    doRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //发送数据
          //console.log(this.user,instance())
          let send_data = {}; // 空对象
          // 将 user 中所有的属性拷贝到其中
          for (let key in this.user) {
            send_data[key] = this.user[key];
          }
          send_data.createtime = new Date().getTime();

          instance.post("/user/register", send_data).then(res => {
            if (res.status === 200) {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.$router.replace('/intro')
              } else {
                //服务端返回错误
                this.$message.error(res.data.msg)
              }
            }
          }).catch((err) => {
            console.log(err)
            this.$message.error('ERROR:' + " 网络错误，请重试！")
          });
        } else {
          //表单验证
          this.$message.error("请检查输入的内容是否符合要求。")
          return false;
        }
      })

    },
  },
  mounted() {
    // 验证码倒计时
    if (!this.timer) {
      this.count = TIME_COUNT
      this.btn_censent = true
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
        } else {
          this.btn_censent = false
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

h1 {
  font-size: 3em;
  text-align: center;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 6px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-- start editing from here --*/
a {
  text-decoration: none;
}

.txt-rt {
  text-align: right;
}

/* text align right */
.txt-lt {
  text-align: left;
}

/* text align left */
.txt-center {
  text-align: center;
}

/* text align center */
.float-rt {
  float: right;
}

/* float right */
.float-lt {
  float: left;
}

/* float left */
.clear {
  clear: both;
}

/* clear float */
.pos-relative {
  position: relative;
}

/* Position Relative */
.pos-absolute {
  position: absolute;
}

/* Position Absolute */
.vertical-base {
  vertical-align: baseline;
}

/* vertical align baseline */
.vertical-top {
  vertical-align: top;
}

/* vertical align top */
nav.vertical ul li {
  display: block;
}

/* vertical menu */
nav.horizontal ul li {
  display: inline-block;
}

/* horizontal menu */
img {
  max-width: 100%;
}

/*-- end reset --*/

.wrapper {
  height: 100%;
}

/*-- main --*/
.login {
  padding: 3em 0 0;
}

.login-wrap {
  width: 35%;
  margin: 3.5em auto;
  background: rgba(41, 5, 105, 0.43);
  background-size: cover;
}

.agileits-top {
  padding: 3em 3em 3em;
}

input[type="text"], input[type="password"] {
  font-size: 1em;
  color: #fff;
  font-weight: 100;
  margin: 3em 0 0;
  width: 93%;
  display: block;
  border: none;
  padding: 1em;
  border-bottom: solid 1px #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 96%, #fff 4%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #fff 4%);
  background-position: -800px 0;
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
}

.text:focus, .text:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
}

.text:focus::-webkit-input-placeholder, .text:valid::-webkit-input-placeholder {
  color: #FFC107;
  font-size: .9em;
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  visibility: visible !important;
}

::-webkit-input-placeholder {
  color: #fff;
  font-weight: 100;
}

:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

input[type="submit"] {
  font-size: 1em;
  color: #fff;
  background: #6439af;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1em;
  -webkit-appearance: none;
  width: 100%;
  margin: 3em 0;
}

input[type="submit"]:hover {
  background: #8a2be2;
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  -o-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px);
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -o-transition: .5s all;
  -ms-transition: .5s all;
  transition: .5s all;
}

.agileits-top p {
  font-size: 1em;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
}

.agileits-top p a {
  color: #fff;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  transition: .5s all;
}

.agileits-top p a:hover {
  color: #FFC107;
}

@keyframes rippling {
  50% {
    border-left-color: #FFC107;
  }
  100% {
    border-bottom-color: #FFC107;
    border-left-color: #FFC107;
  }
}

.wrapper {
  position: relative;
  overflow: auto;
}

.w3lsg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.w3lsg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  -moz-animation: square 25s infinite;
  -o-animation: square 25s infinite;
  -ms-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  transition-timing-function: linear;
}

.w3lsg-bubbles li:nth-child(1) {
  left: 10%;
}

.w3lsg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  -moz-animation-duration: 17s;
  -o-animation-duration: 17s;
  animation-duration: 17s;
}

.w3lsg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
  animation-delay: 4s;
}

.w3lsg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  -moz-animation-duration: 22s;
  -o-animation-duration: 22s;
  -ms-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}

.w3lsg-bubbles li:nth-child(5) {
  left: 70%;
}

.w3lsg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}

.w3lsg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  -moz-animation-delay: 7s;
  -o-animation-delay: 7s;
  -ms-animation-delay: 7s;
  animation-delay: 7s;
}

.w3lsg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  -moz-animation-duration: 40s;
  animation-duration: 40s;
}

.w3lsg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}

.w3lsg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

/*-- responsive-design --*/
@media (max-width: 1280px) {
  h1 {
    font-size: 2.6em;
  }

  .login-wrap {
    width: 40%;
  }
}

@media (max-width: 1080px) {
  .login-wrap {
    width: 45%;
  }
}

@media (max-width: 991px) {
  h1 {
    font-size: 2.4em;
  }
}

@media (max-width: 900px) {
  input[type="text"], input[type="password"] {
    margin: 2.5em 0 0;
    width: 90%;
  }

  .login-wrap {
    width: 52%;
    margin: 3em auto;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 2.2em;
  }
}

@media (max-width: 736px) {
  .agileits-top {
    padding: 0.5em 2.5em 2.5em;
  }

  .login-wrap {
    width: 62%;
    margin: 3em auto;
  }
}

@media (max-width: 667px) {
  .login-wrap {
    width: 67%;
  }

  input[type="submit"] {
    padding: 0.9em;
  }
}

@media (max-width: 600px) {
  input[type="text"], input[type="password"] {
    padding: 1em 0.9em;
  }

  input[type="submit"] {
    margin: 2.5em 0;
  }

  h1 {
    font-size: 2em;
    letter-spacing: 5px;
  }
}

@media (max-width: 568px) {
  .login-wrap {
    width: 75%;
    margin: 2em auto;
  }

}

@media (max-width: 414px) {
  h1 {
    font-size: 1.8em;
    letter-spacing: 2px;
  }

  .login-wrap {
    width: 85%;
    margin: 1.5em auto;
  }

  .agileits-top {
    padding: 0.5em 2em 2em;
  }

  input[type="text"], input[type="password"] {
    font-size: .9em;
    width: 95%;
    padding: .8em .5em;
  }

  .text:focus, .text:valid {
    background-position: 0 0px;
  }

  input[type="submit"] {
    font-size: 0.9em;
  }

  .agileits-top p {
    font-size: 0.9em;
  }

}

@media (max-width: 384px) {
  .login-wrap {
    width: 88%;
  }

  input[type="text"], input[type="password"] {
    width: 94%;
  }

}

@media (max-width: 375px) {
  .agileits-top p {
    letter-spacing: 0px;
  }
}

@media (max-width: 320px) {
  .login {
    padding: 1.5em 0 0;
  }

  h1 {
    font-size: 1.5em;
    letter-spacing: 1px;
  }

  .agileits-top {
    padding: 0.5em 1.5em 1.8em;
  }

  .main-agileinfo {
    width: 92%;
  }

  .text:focus, .text:valid {
    background-position: 0 0;
  }

  input[type="text"], input[type="password"] {
    font-size: .85em;
    margin: 1.8em 0 0;
  }

  input[type="submit"] {
    margin: 1.5em 0;
    padding: 0.8em;
  }
}

</style>
