<template>
  <div class="music">
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <!-- 这里显示提示-->
        <h3 class="tips">聆听音乐，聆听自我</h3>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <el-row class="window" type="flex" justify="space-between">
          <el-col :span="9" class="player">
            <!--音乐播放器部分-->
            <el-row class="player-header">
              <el-col :span="24" type="flex">
                <div style="float: left;">
                  <el-image
                      style="width: 64px; height: 64px; border-radius: 64px;margin-right: 10px;"
                      :src="current_music.alpic"
                      fit="fill"></el-image>
                </div>
                <div style="color: white;margin-top:10px">
                  <h3 style=" color: white; margin:0 auto">{{ current_music.name }}</h3>
                  <h5 style="color: white; margin:0 auto">{{ current_music.artist }} - {{ current_music.album }}</h5>
                </div>
              </el-col>
            </el-row>
            <el-row class="player-lyric">
              {{ current_music.lrc }}
            </el-row>
            <!--聊天部分-->
            <el-row class="player-control">
              <audio-player
                  ref="audioPlayer"
                  :audio-list="audioList.map(elm => elm.url)"
                  :show-prev-button="false"
                  :show-next-button="false"
                  :show-playback-rate="false"
                  :disabled-progress-drag="true"
                  :disabled-progress-click="true"
                  :show-play-button="true"
                  :isLoop="true"
                  theme-color="#fff"
              />
            </el-row>
          </el-col>
          <el-col :span="14" class="chat">
            <el-row class="chat-header">
              <el-col :span="12">音乐聊天机器人</el-col>
            </el-row>
            <el-row class="chat-content">
              <bot-ui></bot-ui>
            </el-row>
            <el-row class="chat-msg">
              <el-row class="input-text">
                <el-input
                    type="textarea"
                    v-model="dialog_text"
                    :rows="4"
                    placeholder="请输入内容"></el-input>
              </el-row>
              <el-row>
                <el-col>
                  <el-button size="medium" type="primary"
                             @click="msgSend"
                             icon="el-icon-s-promotion"
                             style="float: right;margin-right: 16px;">发 送
                  </el-button>
                </el-col>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BotUi from "../components/BotUi";
import {botui} from '@/components/BotUi';
//import instance from "@/request";

export default {
  name: "Music",
  components: {
    BotUi,
  },
  data: function () {
    return {
      dialog_text: '',
      audioList: [],
      isMounted: false,
      current_music: {
        sid: "None",
        name: "等待播放",
        artist: "等待播放",
        album: '等待播放',
        lrc: "等待播放".split(/,|' '/).join('\n'),
        alpic: require("../assets/imgs/music.png"),
      },
      current_action: 'step1'
    }
  },
  methods: {
    //默认bot对话
    bot: function (msg, actionName, time = 1600) {
      return botui.message.bot({
        type: 'html',
        loading: true,
        photo: require("../assets/imgs/bot_icon.png"),
        delay: time,
        content: msg
      })
    },
    //展示音乐卡片
    musicCard: function () {
      return botui.message.bot({
        type: 'music',
        loading: true,
        photo: require("../assets/imgs/bot_icon.png"),
        delay: 1600,
        content: this.current_music
      }).then(() => {
        this.audioList = [{
          name: this.current_music.name,
          url: `https://musicbot-1251052535.cos.accelerate.myqcloud.com/musics/music/${this.current_music.sid}.mp3`
        }]
      })
    },
    //展示评论卡片
    commentCard: function () {
      botui.message.bot({
        type: 'comments',
        loading: true,
        photo: require("../assets/imgs/bot_icon.png"),
        delay: 1600,
        content: [{
          user: "menig323",
          content: "儿子妈妈想你了，你是妈妈最疼的，无法治好的，随时复发的病。妈妈只为了你的健康幸福快乐而坚强的活着，等妈老了，看到你健康，幸福就知足。儿子妈妈努力去改变了生活，但没有能力去改变生命。妈妈对不起你，很想把你塞到肚子里天天在一起，想念我们一个身体有两个生命的那种幸福的感觉... 妈妈爱你❤！"
        }, {
          user: "奔跑的蜗牛zh",
          content: "一心参军的我，都知道母亲不愿让我离去，但也笑着赞许我，这是一个母亲对儿子的爱。。歌曲的单曲循环，让眼泪在眼中不停打转！"
        }, {
          user: "张诚源",
          content: "最开始以为是情歌。班里有个女孩子还想着让我唱这首歌给她听。后来才明白这是给母亲的歌，可是那个女孩子已经不再需要我给她唱歌了"
        }]
      })
    },
    msgSend: function () {
      if (this.current_action === 'step1') {
        this.human(this.dialog_text).then(() => {
          this.dialog_text = ''
          this.bot("似乎是这样的场景吧。", '').then(() => {
            botui.message.bot({
              type: 'html',
              loading: true,
              photo: require("../assets/imgs/bot_icon.png"),
              delay: 1600,
              content: '<img src="https://obsidian-1251052535.cos.ap-hongkong.myqcloud.com/imgs/DALL%C2%B7E%202023-06-06%2023.58.38.png" width="640px" height="100%">'
            }).then(() => {
              this.bot("尝试沉浸在这段怀旧的经历中，谈谈你的感受吧", '').then(() => {
                this.current_action = 'step2'
              })
            })
          })
        })
      }
      if (this.current_action === 'step2') {
        this.human(this.dialog_text).then(() => {
          this.dialog_text = ''
          this.bot("嗯嗯，我能理解你的感受，不过妈妈陪伴我们一起长大的时光还是美好而难忘的。", '').then(() => {
            this.bot("那么，能不能分享一下和妈妈的美好时光?", '').then(() => {
              this.current_action = 'step3'
            })
          })
        })
      }
      if (this.current_action === 'step3') {
        this.human(this.dialog_text).then(() => {
          this.dialog_text = ''
          this.bot("谢谢你的分享！", '').then(() => {
            this.bot("让我继续欣赏这首歌曲。", '').then(() => {
              this.current_action = 'step3'
            })
          })
        })
      }
    },
    //默认user对话
    human: function (msg) {
      return botui.message.human({
        content: msg
      })
    },
    start: function () {
      this.bot("你好，我是音乐聊天机器人，很高兴认识你！我可以陪你聊天，也可以陪你听音乐。", 'start').then(() => {
        this.bot("今天我为你准备了来自beyond的歌曲《真的爱你》。", 'ask_start_emotion').then(() => {
          this.bot("那么让我们开始今天的音乐之旅吧！", 'start_music').then(() => {
            botui.action.button({
              addMessage: false,
              human: false,
              action: [{
                text: '开始',
                value: '开始'
              }],
            }).then((res) => {
              this.human(res.text)
              this.play_music()
            })

          })
        })
      })
    },

    /* ================
     * chatbot 动作
      =================*/

    play_music: function () {
      this.current_music = {
        sid: "347720",
        name: "真的爱你",
        artist: "Beyond",
        album: 'Beyond Ⅳ',
        lrc: " 作词 : 小美, 作曲 : 黄家驹,编曲 : Beyond,监制 : 林邝培 / Beyond,弦乐编排 : 林邝培,,家驹：,无法可修饰的一对手,带出温暖永远在背后,纵使啰嗦始终关注,不懂珍惜太内疚,,家强：,沉醉于音阶她不赞赏,母亲的爱却永未退让,决心冲开心中挣扎,亲恩终可报答,,家驹：,春风化雨暖透我的心,一生眷顾无言地送赠,是你多么温馨的目光,教我坚毅望着前路,叮嘱我跌倒不应放弃,没法解释怎可报尽亲恩,爱意宽大是无限,请准我说声真的爱你,,Paul：,无法可修饰的一对手,带出温暖永远在背后,纵使啰嗦始终关注,不懂珍惜太内疚,,世荣：,仍记起温馨的一对手,始终给我照顾未变样,理想今天终于等到,分享光辉盼做到,,家驹：,春风化雨暖透我的心,一生眷顾无言地送赠,是你多么温馨的目光,教我坚毅望着前路,叮嘱我跌倒不应放弃,没法解释怎可报尽亲恩,爱意宽大是无限,请准我说声真的爱你,,春风化雨暖透我的心,一生眷顾无言地送赠,是你多么温馨的目光,教我坚毅望着前路,叮嘱我跌倒不应放弃,没法解释怎可报尽亲恩,爱意宽大是无限,请准我说声真的爱你,,三子：,是你多么温馨的目光（家驹：哦）,教我坚毅望着前路,叮嘱我跌倒不应放弃（哦）,没法解释怎可报尽亲恩（哦）,爱意宽大是无限,请准我说声真的爱你（哦）".split(/,|' '/).join('\n'),
        alpic: "https://p1.music.126.net/TR1nnl03skxJiz_dtqqsEg==/109951167193891434.jpg",
      }
      this.musicCard('play_music').then(() => {
        this.isMounted = true
        this.$refs.audioPlayer.play()
        //设置起始播放时间
        this.current_music.playing_time = new Date().getTime()
      }).catch(() => {
        this.$message.error("很抱歉，网络开小差了，请您刷新页面继续之前的操作！")
      })
    }
    ,

// 播放下一首前做的事情
  },
  watch: {
    play_time: {
      handler() {
        if (this.play_time > 1 && this.play_time < 2) {
          this.current_music.duration = this.$refs.audioPlayer.duration;
          console.log(this.$refs.audioPlayer.currentTime)
        }
        if (this.play_time > 10 && this.play_time < 11) {
          this.bot("\"怀旧\"是指对过去的眷恋和怀念", '').then(() => {
            this.bot("当你听到这首歌的时候你是否想起你过去的往事？").then(() => {
              botui.action.button({
                addMessage: false,
                human: false,
                action: [{
                  text: '是的',
                  value: '是的'
                }, {
                  text: '没有',
                  value: '没有'
                }],
              }).then(() => {
                this.human("是的").then(() => {
                  this.bot("以下是一些听友听到这首歌想到的，你都想到了什么呢？快来告诉我吧！", '').then(() => {
                    this.commentCard()
                  })
                })
              })
            })
          })
        }
        if (this.play_time > 60 && this.play_time < 61) {
          console.log("hello")
        }
        //console.log(this.play_time);
      }
      ,
      deep: true
    }
  }
  ,
  computed: {
    play_time() {
      if (this.isMounted) return this.$refs.audioPlayer.currentTime;
      else return 0
    },
  }
  ,
  mounted() {
    this.start()
  }
}
</script>

<style scoped>

#musicTag > .el-radio-button {

}

.el-button--primary {
  background: rgba(71, 73, 106, 1) !important;
  border-color: rgba(71, 73, 106, 1) !important;
}

.el-button--primary:hover {
  background: rgba(71, 73, 106, 0.8) !important;
  border-color: rgba(71, 73, 106, 0.8) !important;
  color: #FFF !important;
}

.el-button--primary.is-disabled {
  background: rgba(71, 73, 106, 0.8) !important;
  border-color: rgba(71, 73, 106, 0.3) !important;
  color: #FFF !important;
}

.el-button--default:hover {
  background: rgba(43, 44, 65, 1) !important;
  border-color: rgba(43, 44, 65, 1) !important;
  color: #FFF !important;
}

.music {
  padding: 20px 0 54px 0;
  box-sizing: border-box;
}

.window {
  height: 640px;
  border-radius: 10px;
}

.player {
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
}

.player-header {
  height: 80px;
  background: rgba(71, 73, 106, 0.70);
  background-size: cover;
  border-radius: 10px 10px 0 0;
  padding: 8px;
}

.player-lyric {
  border-top: 1px rgba(71, 73, 106, 0.6) solid;
  background: rgba(71, 73, 106, 0.43);
  background-size: cover;
  height: 450px;
  overflow: auto;
  padding: 24px;
  color: #f5f5f5;
  white-space: pre-wrap;
}

.player-control {
  height: 110px;
  background: rgba(71, 73, 106, 0.70);
  background-size: cover;
  padding: 10px 10px 10px 15px;
  border-radius: 0 0 10px 10px;
}

.chat {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
}

.chat-header {
  background-color: rgba(71, 73, 106, 0.70);
  margin: 0 auto;
  padding-left: 16px;
  align-items: center;
  height: 60px;
  position: relative;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
  border-radius: 10px 10px 0 0;
}

.chat-content {
  height: 442px;
}

.chat-msg {
  height: 138px;
  border-radius: 0 0 10px 10px;
  background-color: white;
}

.tips {
  text-align: center;
  color: #f5f5f5;
}
</style>

<style>
.input-text .el-textarea__inner {
  border: none !important;
  resize: none !important;
  border-radius: 0 !important;
}

.input-text > .el-textarea.is-disabled > .el-textarea__inner {
  background-color: #fff !important;
}
</style>
