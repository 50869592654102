<template>
  <div class="prestudy">
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <el-card class="box-card" shadow="hover">
          <h1>用户实验-后测问卷 (续)</h1>
          <el-form ref="postStudyform_3" :model="poststu_3" label-position="top">
            <el-form-item
                label="当你使用这个音乐聊天机器人聆听音乐时，什么情况下你会感受到有共鸣 (触动到了你内心的情感，想法或者回忆)?"
                :rules="ans_check" prop="q1">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 10}"
                  placeholder="请输入内容"
                  v-model="poststu_3.q1">
              </el-input>
            </el-form-item>
            <el-form-item
                label="当你在“聆听音乐·聆听自我”过程中感受到有共鸣时，你会愿意跟音乐聊天机器人交流你的感受吗? 为什么?"
                :rules="ans_check" prop="q2">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 10}"
                  placeholder="请输入内容"
                  v-model="poststu_3.q2">
              </el-input>
            </el-form-item>
            <el-form-item
                label="你觉得通过这个音乐聊天机器人“聆听音乐·聆听自我“是否能帮助你更好地了解和表达自己的情感和想法? 可以具体说一下你的使用感受吗? "
                :rules="ans_check" prop="q3">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 10}"
                  placeholder="请输入内容"
                  v-model="poststu_3.q3">
              </el-input>
            </el-form-item>
            <el-form-item prop="interview" label="在本次实验结束之后，您是否愿意参加我们后续的访谈？"
                          :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="poststu_3['interview']">
                <el-radio-button label="1">愿意</el-radio-button>
                <el-radio-button label="0">不愿意</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="text-align: left">
              <el-button round type="primary" icon="el-icon-s-promotion" @click="onSubmit('postStudyform_3')">提交
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import instance from "@/request";

export default {
  name: "PostStudyQue3",
  data() {
    return {
      ans_check: [{required: true, message: '请输入内容。', trigger: 'blur'}, {
        min: 2,
        message: '您输入的内容太短！',
        trigger: 'blur'
      }],
      //问卷内容放在这里
      poststu_3: {},
      //问题部分

    };
  },
  mounted() {
    //获得当前页载入时间作为问卷开始时间
    if (sessionStorage.getItem("post_study") === '1') {
      this.$loading({
        lock: true,
        text: '您以及提交过该问卷，请不要重复提交！',
        spinner: 'el-icon-error',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.poststu_3);
          this.poststu_3.end_time = new Date().getTime()
          instance.post("/que/post_que3", this.poststu_3).then(res => {
            if (res.status === 200) {
              if (res.data.code === 2) {
                //直接访问
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  center: true,
                  duration: 1500,
                  onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    this.$router.push({path: "/post1"});
                  }
                })
              } else {
                //在该页面防止重复提交
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  center: true,
                  duration: 1500,
                  onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    this.$router.replace({path: "/thanks"});
                  }
                })
              }
            } else {
              this.$message.error("错误，请重试！")
            }
          }).catch(() => {
            this.$message.error("错误，请重试！")
          });
        } else {
          this.$message.error("请将问卷填写完整！")
          return false;
        }
      });
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prestudy {
  padding: 64px 0;
}
</style>
