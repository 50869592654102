import axios from 'axios'
import router from '@/router'
import {Message} from "element-ui";

const instance = axios.create({
    baseURL: "https://www.chatbot.fans:8081",
    //baseURL: "http://127.0.0.1:8000",
    timeout: 12000,
})
instance.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token')
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}, (error) => {
    Promise.reject(error)
})

instance.interceptors.response.use((response) => {
    return response
}, ({response}) => {
    console.log(response.status)
    const {status} = response
    if (status === 401) {
        Message.error("身份验证错误！")
        router.push({name: 'Login'}).catch()
    }
    return Promise.reject(response)
},)

export default instance
