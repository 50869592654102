import Vue from 'vue'
import VueRouter from 'vue-router'
import Music from '@/views/Music'
import Tutorial from '@/views/Tutorial'
import Login from '@/views/Login'
import Register from "@/views/Register";
import PreStudyQue1 from "@/views/PreStudyQue1";
import PreStudyQue2 from "@/views/PreStudyQue2";
import Introduction from "@/views/Introduction";
import PostStudyQue1 from "@/views/PostStudyQue1";
import PostStudyQue2 from "@/views/PostStudyQue2";
import PostStudyQue3 from "@/views/PostStudyQue3";
import Thanks from "@/views/Thanks";

Vue.use(VueRouter)

const routes = [
    //以下页面不需要登录验证
    {
        path: '/',
        name: 'Home',
        component: Music,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/intro',
        name: 'Introduction',
        component: Introduction
    },
    //以下页面需要登录验证
    {
        path: '/music',
        name: 'Music',
        component: Music,
        //指定路由是否需要验证
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/tutorial',
        name: 'Tutorial',
        component: Tutorial,
        //指定路由是否需要验证
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pre1',
        name: 'PreStudyQue1',
        component: PreStudyQue1,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pre2',
        name: 'PreStudyQue2',
        component: PreStudyQue2,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/post1',
        name: 'PostStudyQue1',
        component: PostStudyQue1,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/post2',
        name: 'PostStudyQue2',
        component: PostStudyQue2,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/post3',
        name: 'PostStudyQue3',
        component: PostStudyQue3,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: Thanks,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior (to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if (savedPosition) {
            return savedPosition
        }
        return {
            x: 0,
            y: 0
        }
    }
})

//创建路由守卫，在路由跳转前验证是否有权限
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        //获取到本地的token
        const token = sessionStorage.getItem("token")
        console.log(!token)
        //
        if (!token) next({name: 'Login'})
        else next()
    } else {
        next()
    }
})

export default router
