<template>
  <div class="prestudy">
    <el-row type="flex" justify="center">
      <el-col :span="22">
        <el-card class="box-card" shadow="hover">
          <h1>用户实验-前测问卷 (续)</h1>
          <el-form ref="preStudyform_2" :model="prestu_2" label-position="top">
            <h3>
              下列题目目的在于了解您<u><font color='red'>过去一周</font></u>对自己生活和情绪的感受。请认真阅读下列句子的描述，尤其注意体会不同句子表述间的差异，并根据自己的实际情况，选出这些项目在您身上的发生频率。</h3>
            <h4>
              其中：1. 几乎从不（0-10%）; 2. 有时（11-35%）; 3. 大概一半的时间（36-65%）; 4. 大部分时间（66-90%）; 5. 几乎总是（91-100%）
            </h4>
            <el-form-item :prop="item.t" v-for="(item, index) in q1group" :label="index+1+'.'+ item.q" :key="item.t"
                          :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="prestu_2[item.t]">
                <el-radio-button label="1">几乎从不</el-radio-button>
                <el-radio-button label="2">有时</el-radio-button>
                <el-radio-button label="3">大概一半的时间</el-radio-button>
                <el-radio-button label="4">大部分时间</el-radio-button>
                <el-radio-button label="5">几乎总是</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <br>
            <h3>
              下列题目旨于了解您对自我的感受。请仔细阅读下列句子的描述，选择最符合您个人真实感受的选项。
            </h3>
            <h4>
              1. 非常不同意，2. 不同意，3. 有点不同意，4. 不同意也不反对，5. 有点同意，6. 同意，7. 非常同意。
            </h4>
             <el-form-item :prop="item.t" v-for="(item, index) in q2group" :label="17+index+1+'.'+ item.q" :key="item.t"
                          :rules="[{ required: true, message: '请选择该项目。'}]">
              <el-radio-group v-model="prestu_2[item.t]">
                <el-radio-button label="1">非常不同意</el-radio-button>
                <el-radio-button label="2">不同意</el-radio-button>
                <el-radio-button label="3">有点不同意</el-radio-button>
                <el-radio-button label="4">不同意也不反对</el-radio-button>
                <el-radio-button label="5">有点同意</el-radio-button>
                <el-radio-button label="6">同意</el-radio-button>
                <el-radio-button label="7">非常同意</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="text-align: left">
              <el-button round type="primary" icon="el-icon-s-promotion" @click="onSubmit('preStudyform_2')">下一步
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import instance from "@/request";

export default {
  name: "PreStudyQue2",
  data() {
    return {
      //问卷内容放在这里
      prestu_2: {},
      //问题部分
      q1group: [
        {q: "我对未来感到乐观。", t: "wellbeing_pre_1"},
        {q: "我感觉自己是有用的。", t: "wellbeing_pre_2"},
        {q: "我感觉自己是放松的。", t: "wellbeing_pre_3"},
        {q: "我能够处理好问题。", t: "wellbeing_pre_4"},
        {q: "我能清楚地思考。", t: "wellbeing_pre_5"},
        {q: "我感觉和别人的关系亲近。", t: "wellbeing_pre_6"},
        {q: "我能对事情做出自己的决定。", t: "wellbeing_pre_7"},
        {q: "我关注自己的感受。", t: "ders_emo_awareness_pre_1"},
        {q: "当我不高兴的时候，我为自己有那样的感受而觉得尴尬。", t: "ders_nonacceptance_pre_1"},
        {q: "我不知道自己的感受。", t: "ders_emo_clarity_pre_1"},
        {q: "这个题目请选择: 几乎总是。", t: "attention_check_2"},
        {q: "当我不高兴的时候，我会为自己有这样的感受而内疚。", t: "ders_nonacceptance_pre_2"},
        {q: "我难以理解自己的感受。", t: "ders_emo_clarity_pre_2"},
        {q: "我关心自己的感受。", t: "ders_emo_awareness_pre_2"},
        {q: "当我不高兴的时候，我会承认自己情绪的存在。", t: "ders_emo_awareness_pre_3"},
        {q: "我对自己的感受感到困惑。", t: "ders_emo_clarity_pre_3"},
        {q: "当我不高兴的时候，我会为自己有这样的感受而变得恼怒。", t: "ders_nonacceptance_pre_3"},
      ],
      q2group: [
        {q: "我感觉自己孤立于这个世界。", t: "social_connectedness_6"},
        {q: "即使在我认识的人面前，我也没有归属感。", t: "social_connectedness_7"},
        {q: "即使在朋友之间，我也不会有兄弟姐妹/手足的感觉。", t: "social_connectedness_3"},
        {q: "我感觉与其他人的关系是疏远的。", t: "social_connectedness_11"},
        {q: "我很少觉得与我的同龄人是同在的。", t: "social_connectedness_13"},
        {q: "我发现自己正与社会脱节。", t: "social_connectedness_15"},
        {q: "我感觉自己与大部分周围的人是无关联的。", t: "social_connectedness_18"},
        {q: "我认为自己不归属于任何人或团体。", t: "social_connectedness_20"},
      ],
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.prestu_2.preQue_end_time = new Date().getTime()
          instance.post("/que/pre_que2", this.prestu_2).then(res => {
            if (res.status === 200) {
              if (res.data.code === 2) {
                //直接访问
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  center: true,
                  duration: 1500,
                  onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    this.$router.push({path: "/pre1"});
                  }
                })
              } else {
                sessionStorage.setItem('pre_study', '1')
                this.$message({
                  message: res.data.msg,
                  type: 'success',
                  center: true,
                  duration: 1000,
                  onClose: () => {
                    //此处写提示关闭后需要执行的函数
                    if (sessionStorage.getItem('tutorial') === '0') {
                      this.$router.replace({path: "/tutorial"});
                    } else {
                      this.$router.replace({path: "/music"});
                    }
                  }
                })
              }
            } else {
              this.$message.error("错误，请重试！")
            }
          }).catch(() => {
            this.$message.error("错误，请重试！")
          });
        } else {
          this.$message.error("请将问卷填写完整！")
          return false;
        }
      });
    }

  },
  mounted() {
    if (sessionStorage.getItem("pre_study") === '1') {
      this.$loading({
        lock: true,
        text: '您以及提交过该问卷，请不要重复提交！',
        spinner: 'el-icon-error',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prestudy {
  padding: 64px 0;
}
</style>
